/* eslint-disable @typescript-eslint/no-explicit-any */
import {Add, Cancel, Edit, Save, SentimentVeryDissatisfied} from "@mui/icons-material";
import {
    Alert,
    type AlertColor,
    Box,
    Button,
    Snackbar,
    Stack,
    Typography,
    useTheme,
    Tabs,
    Tab,
    tabClasses,
    darken,
} from "@mui/material";
import {UseMutateFunction, useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import BeforeUnloadDialog from "components/BeforeUnloadDialog";
import {useProject} from "contexts/ProjectContext";
import {type ArrayActions, useArray} from "hooks/useArray";
import React from "react";
import {useBlocker} from "react-router-dom";
import {apiRequest} from "react_ct/requests";
import {colors} from "react_ct/theme";
import {ManualTagSQLOutput, type ManualTag, type ManualTagSQLInput} from "react_ct/types";
import ProjectTagRow from "./components/Tags/ProjectTagRow";
import ArchivedTagRow from "./components/Tags/ArchivedTagRow";
import {projectKeys} from "queries/queries";

const normalizedRGBToHexString = (red: number, green: number, blue: number): string => {
    const redVal = Math.round(red * 255);
    const greenVal = Math.round(green * 255);
    const blueVal = Math.round(blue * 255);

    const redHex = redVal.toString(16);
    const greenHex = greenVal.toString(16);
    const blueHex = blueVal.toString(16);

    const formatHex = (hex: string): string => {
        if (hex.length === 1) return `0${hex}`;
        else return hex;
    };

    const hexString = `#${formatHex(redHex)}${formatHex(greenHex)}${formatHex(blueHex)}`;

    return hexString;
};

export const convertTagColorToHex = (tag: ManualTagSQLOutput): ManualTag => {
    const hexColor = normalizedRGBToHexString(tag.red_normalized, tag.green_normalized, tag.blue_normalized);
    return {
        id: tag.id,
        name: tag.name,
        definition: tag.definition,
        editable: tag.editable,
        archived: tag.archived,
        color: hexColor,
    };
};

function ArchivedTags({
    tagData,
    setAlert,
}: {
    tagData: ManualTag[];
    setAlert: React.Dispatch<React.SetStateAction<{severity: AlertColor; msg: string} | undefined>>;
}): React.ReactElement {
    const archivedTags: ManualTag[] = tagData.filter(tag => tag.archived);
    if (!archivedTags.length) {
        return (
            <Box
                position="relative"
                mt={6}
                border={theme => `1px solid ${theme.palette.grey[300]}`}
                bgcolor={theme => theme.palette.grey.A100}
                borderRadius={theme => theme.shape.borderRadius / 2}
                height="25vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={1}>
                <Typography variant="h2" textAlign="center" color={theme => theme.palette.grey[500]}>
                    No archived tags available
                </Typography>
            </Box>
        );
    } else {
        return (
            <Stack mt={6}>
                {archivedTags.map(tag => (
                    <ArchivedTagRow key={tag.id} {...{tag, setAlert}} />
                ))}
            </Stack>
        );
    }
}

function UnarchivedTags({
    editState,
    setEditState,
    saveTags,
    tagData,
    tempTagData,
    setTempTagData,
}: {
    editState: boolean;
    setEditState: React.Dispatch<React.SetStateAction<boolean>>;
    saveTags: UseMutateFunction<AxiosResponse<any, any> | undefined, Error, void, unknown>;
    tagData: ManualTag[];
    tempTagData: ManualTag[];
    setTempTagData: ArrayActions<ManualTag>;
}): React.ReactElement {
    return (
        <Box width="100%" margin="auto" id="page-body" mt={6}>
            <Stack margin="auto" mt={2}>
                <Stack direction="row" spacing={4} mb={2} alignItems="baseline">
                    <Box flexBasis="20px" />
                    <Box flexBasis="25%" flexShrink={0}>
                        <Typography
                            fontWeight="bold"
                            textAlign="left"
                            color={theme => theme.palette.midnightBlue.light}>
                            Tag Name
                        </Typography>
                    </Box>
                    <Box flexGrow={1}>
                        <Typography
                            fontWeight="bold"
                            textAlign="left"
                            color={theme => theme.palette.midnightBlue.light}>
                            Tag Definition
                        </Typography>
                    </Box>
                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                        {editState && (
                            <Button
                                color="error"
                                variant="outlined"
                                sx={{borderRadius: 50}}
                                onClick={() => {
                                    setEditState(false);
                                }}>
                                <Stack direction="row" gap={1}>
                                    <Cancel />
                                    <Typography color="error">Discard changes</Typography>
                                </Stack>
                            </Button>
                        )}
                        <Button
                            variant={editState ? "contained" : "outlined"}
                            sx={{borderRadius: 50, transition: "width 0.2s ease-in-out"}}
                            onClick={editState ? () => saveTags() : () => setEditState(true)}>
                            <Stack direction="row" gap={2}>
                                {editState ? <Save /> : <Edit />}
                                {editState && (
                                    <Typography color={editState ? "secondary" : "primary"}>Save changes</Typography>
                                )}
                            </Stack>
                        </Button>
                    </Stack>
                </Stack>
                {tagData.length ? (
                    tagData.map((tag, index) => (
                        <Box key={tag.id ?? index} position="relative">
                            <ProjectTagRow
                                index={index}
                                tag={tag}
                                tagData={editState ? tempTagData : tagData}
                                setTagData={setTempTagData}
                                editState={editState}
                            />
                        </Box>
                    ))
                ) : (
                    <Box
                        position="relative"
                        mt={6}
                        border={theme => `1px solid ${theme.palette.grey[300]}`}
                        bgcolor={theme => theme.palette.grey.A100}
                        borderRadius={theme => theme.shape.borderRadius / 2}
                        height="25vh"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        gap={1}>
                        <SentimentVeryDissatisfied sx={{color: theme => theme.palette.grey[400], fontSize: "2rem"}} />
                        <Typography variant="h2" textAlign="center" color={theme => theme.palette.grey[500]}>
                            No tags available
                        </Typography>
                    </Box>
                )}
            </Stack>
            {editState && (
                <Box
                    border={theme => `1px solid ${theme.palette.grey[300]}`}
                    width="100%"
                    borderRadius={50}
                    mt={6}
                    px={2}
                    py={1}
                    onClick={() => {
                        setTempTagData.push({
                            name: "New tag name",
                            definition: "Give your tag a definition",
                            color: "#ff0000",
                            editable: true,
                        });
                    }}
                    sx={{cursor: "pointer"}}>
                    <Stack direction="row" spacing={1} justifyContent="center">
                        <Add sx={theme => ({color: theme.palette.grey[600]})} />
                        <Typography color={theme => theme.palette.grey[600]}>Add</Typography>
                    </Stack>
                </Box>
            )}
        </Box>
    );
}

export function ProjectTags(): React.ReactElement {
    const qc = useQueryClient();
    const theme = useTheme();
    const projectContext = useProject();
    const {
        currentProject: project,
        projectManualTags: manualTags,
        projectManualTagsError: manualTagsError,
    } = projectContext;
    const [editState, setEditState] = React.useState<boolean>(false);
    const [tempTagData, setTempTagData] = useArray<ManualTag>([]);
    const tagData: ManualTag[] = React.useMemo(() => tempTagData, [editState, tempTagData]);
    const [alertMsg, setAlertMsg] = React.useState<{severity: AlertColor; msg: string}>();
    const [showArchivedTags, setShowArchivedTags] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
        setShowArchivedTags(newValue);
    };

    const blocker = useBlocker(({currentLocation, nextLocation}) => {
        return editState && currentLocation.pathname !== nextLocation.pathname;
    });

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string): void => {
        if (reason === "clickaway") {
            return;
        }

        setAlertMsg(undefined);
    };

    React.useEffect(() => {
        if (manualTags) {
            setTempTagData.overwrite(manualTags);
        }
    }, [manualTags]);

    const saveTagsMutation = useMutation({
        mutationKey: ["saveManualTags"],
        mutationFn: async () => {
            if (project && tempTagData) {
                const manualTags: ManualTagSQLInput[] = tempTagData.map(tag => {
                    const tagColor = tag.color ?? "#000000";
                    const red = parseInt(tagColor.slice(1, 3), 16) / 255;
                    const green = parseInt(tagColor.slice(3, 5), 16) / 255;
                    const blue = parseInt(tagColor.slice(5, 7), 16) / 255;
                    return {
                        id: tag.id,
                        name: tag.name,
                        definition: tag.definition,
                        red_normalized: red,
                        green_normalized: green,
                        blue_normalized: blue,
                        editable: tag.editable,
                        archived: tag.archived,
                    };
                });
                return await apiRequest({
                    path: `project/config/${project.id}/manualTags`,
                    method: "post",
                    data: {manualTags},
                });
            }
        },
        onSuccess: () => {
            void qc.invalidateQueries({queryKey: projectKeys.projectManualTags(project?.id)});
            setEditState(false);
            setAlertMsg({severity: "success", msg: "Changes saved"});
            if (blocker.state === "blocked" && blocker.proceed) blocker.proceed();
        },
        onError: error => {
            setAlertMsg({severity: "error", msg: `Failed to save changes: ${error.message}`});
            if (blocker.state === "blocked" && blocker.reset) blocker.reset();
        },
    });

    return (
        <>
            <BeforeUnloadDialog
                {...{blocker}}
                onSave={() => {
                    saveTagsMutation.mutate();
                }}
                onLeave={() => {
                    if (blocker.proceed) blocker.proceed();
                }}
            />
            <Box p={3} height="100vh" width="67%" margin="auto">
                <Typography variant="h2" component="h2">
                    Manual Tags
                </Typography>
                <Typography component="p" color={theme.palette.midnightBlue.light}>
                    Manual tagging is a feature that allows data collectors to mark sidewalk features that are not
                    automatically collected by DeepWalk. Set the manual tags for your project below.
                </Typography>
                {!manualTagsError && (
                    <Box>
                        <Box sx={{borderBottom: 1, borderColor: "divider", mt: 2}}>
                            <Tabs
                                value={showArchivedTags}
                                onChange={handleChange}
                                aria-label="Show or hide archived tags">
                                <Tab
                                    label="Available Tags"
                                    id={`show-available-tags`}
                                    aria-controls="show-available-tags"
                                    sx={{
                                        [`&.${tabClasses.textColorPrimary}:not(.${tabClasses.selected})`]: {
                                            color: darken(colors.gray, 0.4),
                                        },
                                    }}
                                />
                                <Tab
                                    label="Archived Tags"
                                    id="show-archived-tags"
                                    aria-controls="show-archived-tags"
                                    sx={{
                                        [`&.${tabClasses.textColorPrimary}:not(.${tabClasses.selected})`]: {
                                            color: darken(colors.gray, 0.4),
                                        },
                                    }}
                                />
                            </Tabs>
                        </Box>
                        <Box
                            role="tabpanel"
                            hidden={showArchivedTags > 0}
                            id="show-available-tags-panel"
                            aria-labelledby="show-available-tags">
                            <UnarchivedTags
                                {...{editState, setEditState, tempTagData, tagData, setTempTagData}}
                                saveTags={saveTagsMutation.mutate}
                            />
                        </Box>
                        <Box
                            role="tabpanel"
                            hidden={!showArchivedTags}
                            id="show-archived-tags-panel"
                            aria-labelledby="show-archived-tags">
                            <ArchivedTags {...{tagData}} setAlert={setAlertMsg} />
                        </Box>
                    </Box>
                )}

                {manualTagsError && (
                    <Box
                        position="relative"
                        mt={6}
                        border={`1px solid ${theme.palette.grey[300]}`}
                        bgcolor={theme.palette.grey.A100}
                        borderRadius={theme.shape.borderRadius / 2}
                        height="25vh"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        gap={1}>
                        <SentimentVeryDissatisfied sx={{color: theme.palette.grey[400], fontSize: "2rem"}} />
                        <Typography variant="h2" textAlign="center" color={theme.palette.grey[500]}>
                            {manualTagsError}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                autoHideDuration={3000}
                open={!!alertMsg}
                onClose={handleClose}>
                <Alert severity={alertMsg?.severity}>{alertMsg?.msg}</Alert>
            </Snackbar>
        </>
    );
}
