import React from "react";
import {
    Box,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    type SelectChangeEvent,
    Stack,
    Typography,
    InputAdornment,
    OutlinedInput,
} from "@mui/material";
import {SelectMultiple} from "react_ct/components";
import {CleanMapScan, type ProjectUser} from "react_ct/types";
import {colors} from "react_ct/theme";
import {STAGE_COLORS, STAGE_OPTIONS} from "../constants";
import {DateTimePicker} from "@mui/x-date-pickers";
import type dayjs from "dayjs";
import type {ScanLengthData} from "helpers/utils";
import {useAuth} from "contexts/AuthContext";
import {Search} from "@mui/icons-material";

export default function ScannersStageLength({
    noScanFound,
    setNoScanFound,
    filteredScans,
    selectedScan,
    setSelectedScan,
    projectUsersData,
    selectedProjectUsers,
    setSelectedProjectUsers,
    selectedStages,
    setSelectedStages,
    startDate,
    setStartDate,
    selectedLengthData,
    totalLengthData,
    displayMiles,
}: {
    noScanFound: boolean;
    setNoScanFound: React.Dispatch<React.SetStateAction<boolean>>;
    filteredScans: CleanMapScan[] | undefined;
    selectedScan: CleanMapScan | undefined;
    setSelectedScan: React.Dispatch<React.SetStateAction<CleanMapScan | undefined>>;
    projectUsersData: ProjectUser[];
    selectedProjectUsers: ProjectUser[] | undefined;
    setSelectedProjectUsers: React.Dispatch<React.SetStateAction<ProjectUser[] | undefined>>;
    selectedStages: string[];
    setSelectedStages: React.Dispatch<React.SetStateAction<string[]>>;
    startDate: dayjs.Dayjs | null;
    setStartDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
    selectedLengthData: ScanLengthData | undefined;
    totalLengthData: ScanLengthData | undefined;
    displayMiles: (cm: number) => string;
}): React.ReactElement {
    const {checkIsAdmin} = useAuth();
    return (
        <Stack
            direction="row"
            spacing={0}
            width="100%"
            justifyContent="space-between"
            padding={2}
            sx={{position: "absolute", zIndex: 10}}>
            <Box>
                <Stack direction="row" spacing={5} alignItems="center">
                    {checkIsAdmin && (
                        <FormControl sx={{width: "15rem"}}>
                            <InputLabel
                                id="folder-search-label"
                                sx={theme => ({
                                    fontSize: "0.8rem",
                                    "&.MuiInputLabel-root": {
                                        transform: "translate(7px, -10px) scale(1)",
                                        px: 2,
                                        backgroundColor: theme.palette.deepWalkBlue.dark,
                                        color: theme.palette.text.secondary,
                                        borderRadius: theme.shape.borderRadius,
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontSize: "0.9rem",
                                    },
                                })}>
                                Folder Name
                            </InputLabel>
                            <OutlinedInput
                                error={noScanFound}
                                placeholder="Search for Scan Folder Name"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                }
                                sx={{
                                    [`&.MuiInputBase-root`]: {
                                        backgroundColor: "#fff",
                                        borderRadius: 50,
                                        boxShadow: theme => theme.shadows[1],
                                    },
                                }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const folderName = event.target.value;
                                    if (noScanFound) setNoScanFound(false); // reset no scan found state when the user starts typing to reset textfield error message
                                    if (folderName.length === 36) {
                                        const foundScan = filteredScans?.find(scan => scan.folderName === folderName);
                                        if (foundScan) setSelectedScan(foundScan);
                                        else setNoScanFound(true);
                                    }
                                }}
                            />
                        </FormControl>
                    )}
                    <Box>
                        <SelectMultiple<ProjectUser>
                            label={"Scanners"}
                            options={projectUsersData ?? []}
                            dataKey={"userEmail"}
                            selectedOptions={selectedProjectUsers}
                            setSelectedOptions={setSelectedProjectUsers}
                            sx={theme => ({
                                borderRadius: 50,
                                backgroundColor: "white",
                                padding: 0,
                                boxShadow: theme.shadows[1],
                                "& .MuiSelect-select": {
                                    padding: 1.5,
                                },
                            })}
                        />
                    </Box>
                    <Box>
                        {Boolean(STAGE_OPTIONS.length) && (
                            <FormControl sx={{width: "15rem"}}>
                                <InputLabel
                                    id="stage-select-label"
                                    sx={theme => ({
                                        fontSize: "0.8rem",
                                        "&.MuiInputLabel-shrink": {
                                            transform: "translate(7px, -10px) scale(1)",
                                            px: 2,
                                            backgroundColor: theme.palette.deepWalkBlue.dark,
                                            color: theme.palette.text.secondary,
                                            borderRadius: theme.shape.borderRadius,
                                            fontWeight: theme.typography.fontWeightBold,
                                            fontSize: "0.9rem",
                                        },
                                    })}>
                                    Stage
                                </InputLabel>
                                <Select
                                    label="Stages"
                                    labelId="stage-select-label"
                                    id="stage-select"
                                    multiple
                                    value={selectedStages}
                                    sx={theme => ({
                                        borderRadius: 50,
                                        backgroundColor: "white",
                                        padding: 0,
                                        boxShadow: theme.shadows[1],
                                        "& .MuiSelect-select": {
                                            padding: 1.5,
                                        },
                                    })}
                                    onChange={(event: SelectChangeEvent<string[]>) => {
                                        const stageValues = event.target.value;
                                        setSelectedStages(
                                            Array.isArray(stageValues) ? [...stageValues] : [stageValues],
                                        );
                                    }}
                                    renderValue={selected => selected.join(", ")}>
                                    {STAGE_OPTIONS.map(stage => (
                                        <MenuItem key={stage} value={stage}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}>
                                                <div
                                                    style={{
                                                        height: "10px",
                                                        width: "10px",
                                                        borderRadius: "50%",
                                                        backgroundColor: STAGE_COLORS[stage] ?? colors.deepWalkBlue,
                                                        marginRight: "5px",
                                                    }}
                                                />
                                                <Checkbox checked={selectedStages.includes(stage)} />
                                                {stage}
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                    <FormControl>
                        <InputLabel
                            id="start-date-label"
                            sx={theme => ({
                                fontSize: "0.8rem",
                                "&.MuiInputLabel-root": {
                                    transform: "translate(7px, -10px) scale(1)",
                                    px: 2,
                                    backgroundColor: theme.palette.deepWalkBlue.dark,
                                    color: theme.palette.text.secondary,
                                    borderRadius: theme.shape.borderRadius,
                                    fontWeight: theme.typography.fontWeightBold,
                                    fontSize: "0.9rem",
                                },
                            })}>
                            Start Date
                        </InputLabel>
                        <DateTimePicker
                            value={startDate}
                            onChange={date => setStartDate(date)}
                            sx={{
                                [`& .MuiInputBase-root`]: {
                                    backgroundColor: "#fff",
                                    borderRadius: 50,
                                    boxShadow: theme => theme.shadows[1],
                                },
                            }}
                        />
                    </FormControl>
                </Stack>
            </Box>
            <Box>
                <Paper
                    sx={{
                        zIndex: 10,
                        py: 1.5,
                        px: 2,
                        backgroundColor: colors.lightGray,
                        borderRadius: 50,
                        display: "inline-block",
                    }}>
                    {totalLengthData !== undefined && (
                        <Grid item>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: colors.darkBlue,
                                    "& .MuiTypography-root": {
                                        color: colors.darkBlue,
                                    },
                                }}>
                                Estimated Length (total): <strong>{displayMiles(totalLengthData.totalLength)}</strong>
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        {selectedLengthData !== undefined && (
                            <Typography
                                variant="body2"
                                sx={{
                                    color: colors.darkBlue,
                                    "& .MuiTypography-root": {
                                        color: colors.darkBlue,
                                    },
                                }}>
                                Length (selected stages):{" "}
                                <strong>{displayMiles(selectedLengthData.totalLength)}</strong>
                            </Typography>
                        )}
                    </Grid>
                </Paper>
            </Box>
        </Stack>
    );
}
