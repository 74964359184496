import React, {useEffect, useMemo, useState} from "react";
import mapboxgl from "mapbox-gl";
import {Box, type AlertProps, Paper, FormGroup, FormControlLabel, Switch} from "@mui/material";

import {apiArchiveScan, apiRequest, getCleanProjectScans} from "react_ct/requests";
import {DWAlert} from "react_ct/components";
import DWMap from "components/Map/DWMap";
import {type ScanLengthData, cmToMiles, getScanLengthData} from "helpers/utils";
import type {ProjectUser, MapScan, ReportType, CleanMapScan, CleanScan} from "react_ct/types";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {ConfirmDialog} from "react_ct/components/Confirm/ConfirmDialog";
import {useProject} from "contexts/ProjectContext";
import {type GeoJsonProperties} from "geojson";
import LoadingScreen from "components/LoadingScreen";
import {getLineFeature, getPointFeature, getUnadjustedLineFeature} from "components/Map/helpers";
import {STAGE_COLORS, STAGE_OPTIONS} from "./components/constants";
import ScanDetailsDrawer from "./components/Map/ScanDetailsDrawer";
import ScannersStageLength from "./components/Map/ScannersStageLength";
import {scanKeys} from "queries/queries";
import dayjs from "dayjs";
import LoadingError from "components/LoadingError";

// note: as of CRA 5, this is the only way to access an environment variable without crashing the entire app
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_PK;

mapboxgl.accessToken = MAPBOX_TOKEN ?? "";

export const getScanCoordsString = (scan?: MapScan | CleanMapScan): string => {
    return scan ? `(${scan.lat.toFixed(5)}, ${scan.lng.toFixed(5)})` : "";
};

// type validators

export const isScan = (properties: unknown): properties is CleanMapScan => {
    return (properties as MapScan).scannerId !== undefined;
};

export const isReport = (properties: unknown): properties is ReportType => {
    return (properties as ReportType).accessibility_grade !== undefined;
};

export const isGeoJSONFeature = (feature: unknown): feature is GeoJSON.Feature => {
    return (feature as GeoJSON.Feature).type !== "Feature";
};

export const ScanMap: React.FC = () => {
    const queryClient = useQueryClient();
    const {currentProject: project, projectsError, projectUserList, projectUserListError} = useProject();

    // alert props
    const [alert, setAlert] = useState<{severity: AlertProps["severity"]; message: string}>();
    const openAlert = Boolean(alert);
    // for UI state
    const [filteredScans, setFilteredScans] = useState<CleanMapScan[]>();
    const [mapError, setMapError] = useState<string | undefined>();
    const [archivedScanIds, setArchivedScanIds] = useState<number[]>([]);
    const [selectedScan, setSelectedScan] = useState<CleanMapScan | undefined>(undefined);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedLength, setSelectedLength] = useState<ScanLengthData | undefined>(undefined);
    const [scanUpdating, setScanUpdating] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [showOriginalLines, setShowOriginalLines] = useState(false);
    const [noScanFound, setNoScanFound] = useState(false);

    // selecting stages, projects, and scanner
    const [selectedProjectUsers, setSelectedProjectUsers] = useState<ProjectUser[]>();
    const [selectedStages, setSelectedStages] = useState<string[]>(STAGE_OPTIONS);
    const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);

    const {data: rasterUrl, error: rasterUrlError} = useQuery({
        queryKey: scanKeys.scanRasterUrl(selectedScan?.id),
        queryFn: async () => {
            const response = await apiRequest({
                path: `scan/read-urls`,
                params: {scanId: selectedScan?.id, imageUrl: true},
                adminAuth: true,
            });
            const urls = response.data;
            return urls.imageUrl;
        },
        enabled: !!selectedScan,
    });

    const {
        data: projectScans,
        error: projectScansError,
        isPending: areProjectScansPending,
    } = useQuery({
        queryKey: scanKeys.scans(project?.id),
        queryFn: async () => {
            if (!project?.id) {
                throw new Error("Could not retrieve scans");
            }
            const data = await getCleanProjectScans(project.id);
            const updatedData: CleanMapScan[] = data.map((scan: CleanScan) => {
                const coords = scan.robustGpsCoordinates ?? scan.gpsCoordinates;
                const latlng = coords?.replace("POINT(", "").replace(")", "").split(" ");
                const lat = parseFloat(latlng?.[0] ?? "");
                const lng = parseFloat(latlng?.[1] ?? "");
                return {
                    ...scan,
                    lat,
                    lng,
                    scannerId: scan.userId,
                    gpsMultiline: scan.gpsMultiline?.map(coord => [coord[1], coord[0]]) ?? null,
                    adjustedGpsMultiline: scan.adjustedGpsMultiline?.map(coord => [coord[1], coord[0]]) ?? null,
                    createdAt: scan.createdAt.getTime(),
                    updatedAt: scan.updatedAt.getTime(),
                    confirmed: scan.scanLengthConfirmed,
                };
            });
            return updatedData;
        },
        enabled: !!project,
    });

    // handle errors from useQuery hooks
    useEffect(() => {
        // this error handling could be better https://github.com/DeepwalkInternal/DeepWatch/issues/30
        if (projectsError ?? projectUserListError) {
            const firstError: string = projectsError ?? projectUserListError ?? "";
            setMapError(firstError);
        }
    }, [projectsError, projectUserListError]);

    // cleanup state and useQuery hooks on unmount
    useEffect(() => {
        return () => {
            // reset useQuery data
            queryClient.setQueryData(["getScans", project?.id], null);
            queryClient.setQueryData(["getScanRasterUrl", selectedScan?.id], null);

            // clear all state
            setFilteredScans(undefined);
            setMapError(undefined);
            setSelectedScan(undefined);
            setOpenDrawer(false);
            setSelectedLength(undefined);
            setStartDate(null);
            setScanUpdating(false);
            setArchivedScanIds([]);
        };
    }, []);

    useEffect(() => {
        if (projectUserList) {
            // automatically select all scanners
            setSelectedProjectUsers(
                projectUserList.filter(el => el.role.toLowerCase() === "scanner" || el.role.toLowerCase() === "admin"),
            );
        }
    }, [projectUserList]);

    if (rasterUrlError) setMapError(rasterUrlError.message);
    if (projectScansError) setMapError(projectScansError.message);

    const onMarkerClick = (feature: GeoJSON.Feature): void => {
        isScan(feature.properties) && setSelectedScan(feature.properties);
        setOpenDrawer(true);
    };

    const createFilter = (
        scans: CleanMapScan[],
        selectedStages: string[],
        selectedProjectUsers: ProjectUser[],
        startDate: dayjs.Dayjs | null,
    ): void => {
        const filteredScans = scans.filter(scan => {
            return (
                selectedStages.includes(scan.stage) &&
                selectedProjectUsers.find(el => el.userId === scan.scannerId) &&
                (startDate ? dayjs(scan.createdAt).isAfter(startDate) : true)
            );
        });
        setFilteredScans(filteredScans);
        setSelectedLength(getScanLengthData(filteredScans));
    };

    useEffect(() => {
        const haveValidFilterParams = Boolean(selectedProjectUsers?.length) && Boolean(selectedStages?.length);
        if (projectScans?.length && haveValidFilterParams) {
            createFilter(projectScans, selectedStages, selectedProjectUsers!, startDate);
        } else {
            setFilteredScans([]);
            setSelectedLength(undefined);
        }
    }, [projectScans, selectedStages, selectedProjectUsers, startDate]);

    const filteredScanIds = useMemo(() => {
        // remove archived scans from the filtered scans, since scan.stage is not updated until the page is refreshed
        const filteredScanIds = filteredScans?.map(scan => scan.id).filter(id => !archivedScanIds.includes(id));
        return filteredScanIds;
    }, [filteredScans, archivedScanIds]);

    const archiveScan = async (scanId: number): Promise<void> => {
        setScanUpdating(true);
        try {
            const response = await apiArchiveScan(scanId);
            if (response.status !== 200) {
                throw new Error(`Unexpected response code: ${String(response.status)}`);
            }

            // update state
            setArchivedScanIds(vals => [...vals, scanId]);
            setSelectedScan(undefined);
            setOpenDrawer(false);

            // show success alert
            setAlert({
                severity: "success",
                message: "Success - scan archived",
            });
        } catch (error) {
            console.error(error);
            setAlert({
                severity: "error",
                message: "Error archiving scan",
            });
        } finally {
            setScanUpdating(false);
        }
    };

    const displayMiles = (cm: number): string => {
        const miles = cmToMiles(cm);
        return `${miles.toFixed(2)} miles`;
    };

    const getFeatureColor = (properties: GeoJsonProperties): string => {
        return isScan(properties) ? STAGE_COLORS[properties.stage] : "#fff";
    };

    const mapData: GeoJSON.FeatureCollection = React.useMemo(() => {
        if (projectScans) {
            const lineFeatures: GeoJSON.Feature[] = projectScans
                .map(scan => getLineFeature(scan, getFeatureColor, "adjustedGpsMultiline"))
                .filter(feature => feature !== null) as GeoJSON.Feature[];
            const unadjustedLineFeatures: GeoJSON.Feature[] = projectScans
                .map(scan => getUnadjustedLineFeature(scan))
                .filter(feature => feature !== null) as GeoJSON.Feature[];
            const pointFeatures: GeoJSON.Feature[] = projectScans
                .map(scan => getPointFeature(scan, getFeatureColor))
                .filter(feature => feature !== null) as GeoJSON.Feature[];
            return {
                type: "FeatureCollection",
                features: [...unadjustedLineFeatures, ...lineFeatures, ...pointFeatures],
            };
        }
        return {
            type: "FeatureCollection",
            features: [],
        };
    }, [projectScans]);

    const selectedFeature: GeoJSON.Feature | undefined = React.useMemo(() => {
        if (!selectedScan) return undefined;
        return mapData.features.find(feature => feature.properties?.id === selectedScan.id);
    }, [selectedScan]);

    const scanLengthData: ScanLengthData | undefined = React.useMemo(() => {
        if (projectScans) {
            return getScanLengthData(projectScans);
        }
        return undefined;
    }, [projectScans]);

    return (
        <Box id="page-container" sx={{height: "100vh", width: "100%", position: "relative"}}>
            {!mapError && projectUserList && mapData.features.length ? (
                <>
                    <ScannersStageLength
                        projectUsersData={projectUserList}
                        {...{
                            noScanFound,
                            setNoScanFound,
                            filteredScans,
                            selectedProjectUsers,
                            setSelectedProjectUsers,
                            selectedStages,
                            setSelectedStages,
                            selectedScan,
                            setSelectedScan,
                            startDate,
                            setStartDate,
                            selectedLengthData: selectedLength,
                            totalLengthData: scanLengthData,
                            displayMiles,
                        }}
                    />
                    <Paper
                        elevation={3}
                        sx={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            mb: 4,
                            mr: 24,
                            zIndex: 20,
                            borderRadius: theme => theme.shape.borderRadius,
                            px: 2,
                        }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showOriginalLines}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            setShowOriginalLines(event.target.checked)
                                        }
                                    />
                                }
                                label="Show original scan lines"
                            />
                        </FormGroup>
                    </Paper>
                    <DWMap
                        data={mapData}
                        dataId="id"
                        filterLayers={[
                            {
                                id: "stage",
                                layers: selectedStages,
                            },
                            {
                                id: "scannerId",
                                layers: selectedProjectUsers?.map(user => user.userId) ?? [],
                            },
                            {
                                id: "createdAt",
                                layers:
                                    projectScans
                                        ?.map(scan => scan.createdAt)
                                        .filter(date => dayjs(date).isAfter(startDate ?? 0)) ?? [],
                            },
                            {
                                id: "unadjustedLines",
                                layers: ["false", showOriginalLines ? "true" : ""],
                            },
                        ]}
                        filteredScanIds={filteredScanIds}
                        onMarkerClick={onMarkerClick}
                        selectedFeature={selectedFeature}
                        satelliteControl
                        annotationControl
                    />
                    <ScanDetailsDrawer
                        {...{
                            selectedScan,
                            rasterUrl,
                            openDrawer,
                            setOpenDrawer,
                            setOpenDialog,
                            displayMiles,
                            scanUpdating,
                        }}
                    />
                    <DWAlert
                        openAlert={openAlert}
                        onClose={() => {
                            setAlert(undefined);
                        }}
                        alertMessage={alert?.message}
                        alertSeverity={alert?.severity}
                    />
                    <ConfirmDialog
                        open={openDialog}
                        setOpen={setOpenDialog}
                        title={"Archive scan?"}
                        description={"Are you sure you want to archive this scan? This action cannot be undone."}
                        onConfirm={async () => {
                            if (selectedScan) {
                                await archiveScan(selectedScan.id);
                            }
                        }}
                    />
                </>
            ) : (mapError ?? (!mapData.features.length && !areProjectScansPending)) ? (
                <LoadingError errorMessage={mapError ?? "There are no scans associated with this project."} />
            ) : (
                <LoadingScreen />
            )}
        </Box>
    );
};
