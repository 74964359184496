import {Delete, Edit} from "@mui/icons-material";
import {
    Box,
    ClickAwayListener,
    IconButton,
    Paper,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import {ArrayActions} from "hooks/useArray";
import {useDebounce} from "hooks/useDebounce";
import React from "react";
import {HexColorPicker} from "react-colorful";
import {ManualTag} from "react_ct/types";

export default function ProjectTagRow({
    index,
    tag,
    tagData,
    setTagData,
    editState,
}: {
    index: number;
    tag: ManualTag;
    tagData: ManualTag[];
    setTagData: ArrayActions<ManualTag>;
    editState: boolean;
}): React.ReactElement {
    const theme = useTheme();

    const foundIndex = tag.id ? tagData.findIndex(t => tag.id === t.id) : index;
    const [color, setColor] = React.useState<string>(tag.color ?? "#000000");
    const [tagNameValue, setTagNameValue] = React.useState<string>(tag.name);
    const [tagDefinitionValue, setTagDefinitionValue] = React.useState<string>(tag.definition);
    const [openColorPicker, setOpenColorPicker] = React.useState<boolean>(false);

    const debouncedColor = useDebounce(color, 300);
    const debouncedNameVal = useDebounce(tagNameValue, 300);
    const debouncedDefinitionVal = useDebounce(tagDefinitionValue, 300);

    React.useEffect(() => {
        if (
            foundIndex > -1 &&
            debouncedColor &&
            debouncedColor.length > 0 &&
            debouncedNameVal.length > 0 &&
            debouncedDefinitionVal.length > 0
        ) {
            setTagData.replace(foundIndex, {
                ...tagData[foundIndex],
                color: debouncedColor,
                name: debouncedNameVal,
                definition: debouncedDefinitionVal,
            });
        }
    }, [debouncedColor, debouncedNameVal, debouncedDefinitionVal]);

    React.useEffect(() => {
        setColor(tag.color ?? "#000000");
        setTagNameValue(tag.name);
        setTagDefinitionValue(tag.definition);
    }, [editState]);

    if (tag.archived) return <></>;

    return (
        <Paper variant="outlined" sx={{px: 2, py: 1, my: 1, borderRadius: 50}}>
            <Stack direction="row" alignItems="center" spacing={8}>
                <Stack direction="row" alignItems="center" spacing={2} flexShrink={0} flexBasis="25%">
                    <ClickAwayListener
                        onClickAway={() => {
                            if (openColorPicker) setOpenColorPicker(false);
                        }}>
                        <Box position="relative" flexBasis="20px" flexShrink={0}>
                            {editState && (
                                <Paper
                                    variant="outlined"
                                    sx={theme => ({
                                        position: "absolute",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "10px",
                                        height: "10px",
                                        top: -10,
                                        left: 15,
                                        borderRadius: 50,
                                        padding: 1,
                                        backgroundColor: theme.palette.grey[100],
                                    })}
                                    elevation={0}>
                                    <Edit sx={{fontSize: "12px"}} />
                                </Paper>
                            )}
                            <Box
                                width="20px"
                                height="20px"
                                borderRadius="50%"
                                bgcolor={color}
                                onClick={editState ? () => setOpenColorPicker(prev => !prev) : undefined}
                                border={editState ? `3px solid ${theme.palette.grey[200]}` : undefined}
                                boxSizing="content-box"
                                sx={{
                                    cursor: editState ? "pointer" : "auto",
                                }}
                            />
                            {editState && (
                                <Box
                                    position="absolute"
                                    visibility={openColorPicker ? "visible" : "hidden"}
                                    zIndex={5}
                                    left="-80px"
                                    bottom="-210px">
                                    <HexColorPicker color={color} onChange={setColor} />
                                </Box>
                            )}
                        </Box>
                    </ClickAwayListener>
                    {editState ? (
                        <TextField
                            variant="outlined"
                            aria-label="tag name"
                            placeholder={tag.name}
                            value={tagNameValue}
                            onChange={event => setTagNameValue(event.target.value)}
                        />
                    ) : (
                        <Typography fontWeight="bold" fontSize="1.2rem" alignSelf="flex-start">
                            {tagNameValue}
                        </Typography>
                    )}
                </Stack>

                {editState ? (
                    <TextField
                        aria-label="tag definition"
                        placeholder={tag.definition}
                        style={{flexGrow: 1}}
                        value={tagDefinitionValue}
                        multiline
                        onChange={event => setTagDefinitionValue(event.target.value)}
                    />
                ) : (
                    <Typography flexGrow={1}>{tagDefinitionValue}</Typography>
                )}
                <Box flexBasis="24px">
                    {editState && tag.editable && tag.id && (
                        <Tooltip title="Archive Tag">
                            <IconButton
                                color="error"
                                sx={{padding: 0}}
                                onClick={() => {
                                    const i = tagData.findIndex(t => t.id === tag.id);
                                    if (i > -1) setTagData.replace(i, {...tagData[i], archived: true});
                                }}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </Stack>
        </Paper>
    );
}
