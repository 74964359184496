import * as zip from "@zip.js/zip.js";
import type {CleanMapScan, MapScan} from "react_ct/types";

export const cmToMiles = (cm: number): number => {
    return cm / 160934.4;
};

export const parseIntOrZero = (value: string | undefined): number => {
    const parsed = parseInt(value ?? "0", 10);
    return isNaN(parsed) ? 0 : parsed;
};

export const getErrorMessage = (error: unknown): string => {
    if (error instanceof Error) {
        return error.message;
    } else {
        return String(error);
    }
};

export const unzipFile = async (res: Response): Promise<GeoJSON.FeatureCollection> => {
    const reader = new zip.ZipReader(
        new zip.Uint8ArrayReader(res.body ? new Uint8Array(await res.arrayBuffer()) : new Uint8Array(0)),
    );

    const files: zip.Entry[] = await reader.getEntries();
    void reader.close();

    if (files?.length) {
        const json = await files?.[0].getData?.(new zip.TextWriter());
        const parsedJSON = JSON.parse(json ?? "");
        return parsedJSON;
    } else {
        throw new Error("Could not read file");
    }
};

export const addScanLength = (scan: MapScan): number => {
    const scanCreatedDate = new Date(scan.createdAt);
    if (scan.scanLength) {
        return scan.scanLength;
    } else if (
        // ask michael why only these estimated scan lengths are valid
        !scan.scanLength &&
        scan.estimatedScanLength &&
        scanCreatedDate.getFullYear() >= 2024 &&
        scanCreatedDate.getMonth() >= 5
    ) {
        return scan.estimatedScanLength;
    } else {
        return 0;
    }
};

export const toProperCase = (string: string): string => {
    return `${string[0].toUpperCase()}${string.slice(1)}`;
};

export const capitalizeFirstLetterOfEachWord = (string: string): string => {
    return string
        .split(" ")
        .map(word => toProperCase(word))
        .join(" ");
};

export interface ScanLengthData {
    totalLength: number;
    confirmedLength: number;
}

export const getScanLengthData = (scans: CleanMapScan[]): ScanLengthData => {
    const total = scans.reduce((acc, scan) => acc + scan.scanLength, 0);
    const confirmed = scans.reduce((acc, scan) => acc + (scan.scanLengthConfirmed ? scan.scanLength : 0), 0);
    return {totalLength: total, confirmedLength: confirmed};
};

export function createUniqueId(coordString: string, seed = 0): string {
    let h1 = 0xdeadbeef ^ seed;
    let h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < coordString.length; i++) {
        ch = coordString.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return String(4294967296 * (2097151 & h2) + (h1 >>> 0));
}
