import React from "react";
import {AlertColor, IconButton, Paper, Stack, Tooltip, Typography} from "@mui/material";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useProject} from "contexts/ProjectContext";
import {apiRequest} from "react_ct/requests";
import {ManualTag} from "react_ct/types";
import {Restore} from "@mui/icons-material";
import {projectKeys} from "queries/queries";

export default function ArchivedTagRow({
    tag,
    setAlert,
}: {
    tag: ManualTag;
    setAlert: React.Dispatch<React.SetStateAction<{severity: AlertColor; msg: string} | undefined>>;
}): React.ReactElement {
    const qc = useQueryClient();
    const {currentProject} = useProject();
    const unarchiveTagMutation = useMutation({
        mutationFn: async () => {
            if (!currentProject || !tag.id) throw new Error("No project or tag id available");
            const tempTag = tag;
            delete tempTag.color;
            await apiRequest({
                path: `project/config/${currentProject.id}/manualTags/${tag.id}`,
                method: "put",
                data: {
                    manualTag: {
                        ...tempTag,
                        archived: false,
                    },
                },
            });
        },
        onMutate: () => {
            setAlert({severity: "info", msg: "Moving tag out of archive..."});
        },
        onSuccess: () => {
            setAlert({severity: "success", msg: "Tag successfully moved out of the archive"});
            void qc.invalidateQueries({queryKey: [projectKeys.projectManualTags(currentProject?.id)]});
        },
        onError: error => {
            setAlert({severity: "error", msg: `Could not move tag out of archive: ${error.message}`});
        },
    });
    return (
        <Paper variant="outlined" sx={{px: 2, py: 1, my: 2, borderRadius: 50}}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={8} flexGrow="67%">
                    <Typography fontWeight="bold" fontSize="1.2rem" alignSelf="flex-start">
                        {tag.name}
                    </Typography>
                    <Typography flexGrow={1}>{tag.definition}</Typography>
                </Stack>
                <Tooltip title="Unarchive Scan">
                    <IconButton
                        onClick={() => {
                            void unarchiveTagMutation.mutate();
                        }}>
                        <Restore />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Paper>
    );
}
